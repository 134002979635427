import { combineReducers } from "redux"
import UserReducer from "./UserReducer"
import OrderReducer from "./OrderReducer"
import OrderStatusesReducer from "./OrderStatusesReducer"
import StationReducer from "./StationReducer"
import LockersCellsReducer from "./LockersCellsReducer"
import OrderTypeReducer from "./OrderTypeReducer"
import IsraelPostReducer from "./IsraelPostReducer"
import FailedSMS from "./FailedSMSReducer"

export default combineReducers({
  users: UserReducer,
  orders: OrderReducer,
  orderStatuses: OrderStatusesReducer,
  station: StationReducer,
  lockersCells: LockersCellsReducer,
  orderTypes: OrderTypeReducer,
  israelPost: IsraelPostReducer,
  failedSMS: FailedSMS,
})

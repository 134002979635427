import React, { Component } from "react"
import { connect } from "react-redux"

import { LabeledTextInput } from "../../../common/LabeledTextInput"
import { Spinner } from "../../../common/Spinner"
import ValidationMessage from "../../../common/ValidationMessage"
import {
  isValidField,
  FIELD_TYPE_ENUM,
} from "../../../../utils/FieldsValidation"
import NotificationModal from "../../../common/NotificationModal"
import {
  cleanUserForm,
  createUser,
  editUserForm,
  clearRequestData,
} from "../../../../actions"
import { Button } from "../../../common/Button"
import "./style.scss"

const NewUser_TitleText = "יצירת משתמש"
const NewUser_FName_InputLabelText = "שם פרטי "
const NewUser_LName_InputLabelText = "שם משפחה"
const NewUser_mobilePhone_InputLabelText = "טלפון"
const NewUser_CreateSuccessed_Text = "משתמש נוצר בהצלחה"
const NewUser_CreateFailed_Text = "כישלון ביצירת משתמש"
const NewUser_IsExistError_Text = "משתמש כבר קיים. אנא צור קשר עם שרות לקוחות"

class NewUser extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showConfirmDeletingUser: false,
      isCreateUserButtonDisabled: false,
      firstName: "",
      lastName: "",
      mobilePhone: "",
      validField: {
        lastName: {
          type: "text",
          isRequired: true,
          isChecked: false,
          isValid: false,
        },
        firstName: {
          type: "text",
          isRequired: false,
          isChecked: false,
          isValid: false,
        },
        mobilePhone: {
          type: "mobile",
          isRequired: false,
          isChecked: false,
          isValid: false,
        },
      },
    }
  }

  componentWillUnmount() {
    this.setState({
      isCreateSupplierButtonDisabled: false,
      lastName: "",
      firstName: "",
      mobilePhone: "",
    })
    this.props.cleanUserForm()
  }

  componentWillReceiveProps(newProps) {}

  handleFieldChange(inputName, inputValue) {
    const { user } = this.props
    let _user = { ...user }

    _user = {
      ..._user,
      [inputName]: inputValue,
    }
    this.props.editUserForm(_user)
    this.runValidation(inputName, inputValue)
  }

  onSubmitButtonClick() {
    const { lastName, firstName, mobilePhone } = this.props.user
    const { role } = this.props.userData

    let newUser = {
      lastName,
      firstName,
      mobilePhone,
      stationId: "1CD96853-68C6-4C14-8F99-B43C208A7319",
    }
    if (!this.isValidSupplier(newUser)) {
      return
    }
    this.setState({ isCreateUserButtonDisabled: true })
    //this.props.showLoading()
    this.props.createUser(newUser)
  }

  isValidSupplier(newUser) {
    const fieldsList = ["lastName", "firstName", "mobilePhone"]
    this.runValidationList(fieldsList, newUser)

    const { validField } = this.state
    let res = fieldsList.map((fieldName, i) => {
      if (!validField[fieldName].isValid) {
        console.log(fieldName + " is not valid")
        return false
      }
      return true
    })

    return res.filter((i) => i === true).length === fieldsList.length
  }

  runValidationList(fieldsList, supplier) {
    fieldsList.map((fieldName, i) =>
      this.runValidation(fieldName, supplier[fieldName])
    )
  }

  runValidation(inputName, inputValue) {
    let { validField } = this.state
    let field = validField[inputName]

    if (!field) return
    field.isChecked = true

    field.isValid = isValidField(
      inputValue,
      field.type,
      field.isRequired,
      field.compareMinValue,
      field.compareMaxValue
    )
    validField[inputName] = field

    this.setState({
      ...this.state,
      validField,
    })
  }

  onFieldFocus(inputName) {
    let { validField } = this.state
    let field = validField[inputName]
    if (!field) return
    field.isChecked = false
    validField[inputName] = field
    this.setState({
      ...this.state,
      validField,
    })
  }

  closeForm() {
    this.props.clearRequestData()
    this.props.history.push("/UsersListScreen")
  }

  render() {
    const {
      user,
      isLoading,
      isUserCreatedSuccess,
      isUserCreatedFail,
      isUserExist,
    } = this.props

    const { lastName, firstName, mobilePhone } = user
    const fieldTypeList = FIELD_TYPE_ENUM
    const { validField, isCreateUserButtonDisabled, showConfirmDeletingUser } =
      this.state

    if (isLoading) {
      return (
        <div className="new-user spinner-wrapper">
          <Spinner zoom={2} />
        </div>
      )
    }

    return (
      <>
        <div className="ss-container new-user">
          <h1>{NewUser_TitleText}</h1>
          <div className="section-info-row">
            <label htmlFor="firstName">{NewUser_FName_InputLabelText}</label>
            <input
              type="text"
              id="firstName"
              value={firstName}
              onChange={(e) => {
                this.handleFieldChange("firstName", e.target.value)
              }}
              onFocus={(e) => {
                this.onFieldFocus("firstName")
              }}
              onBlur={(e) => {
                this.runValidation("firstName", firstName)
              }}
            />
            <ValidationMessage
              isShow={validField.firstName.isChecked}
              isShowOk={false}
              isShowError={!validField.firstName.isValid}
              className="field-valid"
              errorMessage={
                fieldTypeList[validField.firstName.type].errMessage +
                " " +
                fieldTypeList["required"].errMessage
              }
            />
          </div>

          <div className="section-info-row">
            <label htmlFor="lastName">{NewUser_LName_InputLabelText}</label>
            <input
              type="text"
              id="lastName"
              value={lastName}
              onChange={(e) => {
                this.handleFieldChange("lastName", e.target.value)
              }}
              onFocus={(e) => {
                this.onFieldFocus("lastName")
              }}
              onBlur={(e) => {
                this.runValidation("lastName", lastName)
              }}
            />
            <ValidationMessage
              isShow={validField.lastName.isChecked}
              isShowOk={false}
              isShowError={!validField.lastName.isValid}
              className="field-valid"
              errorMessage={
                fieldTypeList[validField.lastName.type].errMessage +
                " " +
                fieldTypeList["required"].errMessage
              }
            />
          </div>

          <div className="section-info-row">
            <label htmlFor="mobilePhone">
              {NewUser_mobilePhone_InputLabelText}
            </label>
            <input
              type="text"
              id="mobilePhone"
              value={mobilePhone}
              onChange={(e) => {
                this.handleFieldChange("mobilePhone", e.target.value)
              }}
              onFocus={(e) => {
                this.onFieldFocus("mobilePhone")
              }}
              onBlur={(e) => {
                this.runValidation("mobilePhone", mobilePhone)
              }}
            />
            <ValidationMessage
              isShow={validField.mobilePhone.isChecked}
              isShowOk={false}
              isShowError={!validField.mobilePhone.isValid}
              className="field-valid"
              errorMessage={
                fieldTypeList[validField.mobilePhone.type].errMessage +
                " " +
                fieldTypeList["required"].errMessage
              }
            />
          </div>

          <div className="btn-holder">
            <Button type="submit" onClick={(e) => this.onSubmitButtonClick(e)}>
              צור משתמש
            </Button>
          </div>
        </div>

        <NotificationModal
          show={isUserCreatedSuccess}
          title={NewUser_TitleText}
          text={NewUser_CreateSuccessed_Text}
          onOkClick={() => this.closeForm()}
        />
        <NotificationModal
          show={isUserCreatedFail}
          title={NewUser_TitleText}
          text={
            isUserExist ? NewUser_IsExistError_Text : NewUser_CreateFailed_Text
          }
          onOkClick={() => this.closeForm()}
        />
      </>
    )
  }
}

const mapStateToProp = ({ users, errors }) => {
  const {
    user,
    isLoading,
    isUserCreatedSuccess,
    isUserCreatedFail,
    userData,
    isUserExist,
  } = users
  return {
    user,
    isLoading,
    isUserCreatedSuccess,
    isUserCreatedFail,
    userData,
    isUserExist,
  }
}

export default connect(mapStateToProp, {
  cleanUserForm,
  createUser,
  editUserForm,
  clearRequestData,
  // showLoading,
})(NewUser)

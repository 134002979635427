//RESET ALL
export const RESET_ALL_REDUCERS = "RESET_ALL_REDUCERS"
export const RESET_STATION_REDUCERS = "RESET_STATION_REDUCERS"
//
export const ON_START_LOADING = "ON_START_LOADING"

//USER
export const ON_USER_SIGN_IN_SUCCESS = "ON_USER_SIGN_IN_SUCCESS"
export const ON_USER_SIGN_IN_FAIL = "ON_USER_SIGN_IN_FAIL"
export const ON_USER_SIGN_OUT = "ON_USER_SIGN_OUT"
export const ON_USER_SIGN_IN_REQUEST = "ON_USER_SIGN_IN_REQUEST"
export const ON_CREATE_UPDATE_USER_REQUEST = "ON_CREATE_UPDATE_USER_REQUEST"
export const ON_UPDATE_USER_SUCCESS = "ON_UPDATE_USER_SUCCESS"
export const ON_CREATE_UPDATE_USER_ERROR =
  "ON_ON_CREATE_UPDATE_USER_ERRORUSER_SIGN_OUT"
export const CLEAR_USER_STATE = "CLEAR_USER_STATE"
export const CLEAR_REQUEST = "CLEAR_REQUEST"
export const ON_GET_USERS_LIST_SUCCESS = "ON_GET_USERS_LIST_SUCCESS"
export const ON_GET_USERS_LIST_FAIL = "ON_GET_USERS_LIST_FAIL"
export const ON_USERS_SHOW_LOADING = "ON_USERS_SHOW_LOADING"
export const ON_DELETE_USER_SUCCESS = "ON_DELETE_USER_SUCCESS"
export const ON_DELETE_USER_FAIL = "ON_DELETE_USER_FAIL"
export const CLEAN_USER_FORM = "CLEAN_USER_FORM"
export const ON_CREATE_USER_SUCCESS = "ON_CREATE_USER_SUCCESS"
export const ON_CREATE_USER_ERROR = "ON_CREATE_USER_ERROR"
export const EDIT_USER_FORM = "EDIT_USER_FORM"
export const ON_CREATE_USER_REQUEST = "ON_CREATE_USER_REQUEST"
export const ON_RESET_PASSWORD_SUCCESS = "ON_RESET_PASSWORD_SUCCESS"
export const ON_RESET_PASSWORD_FAIL = "ON_RESET_PASSWORD_FAIL"

//ORDERS LIST
export const ON_FETCH_EXIST_ORDERS_SUCCESS = "ON_FETCH_ORDERS_LIST_SUCCESS"
export const ON_FETCH_EXIST_ORDERS_FAIL = "ON_FETCH_ORDERS_LIST_FAIL"
export const ON_UPDATE_ORDER_STATION_SUCCESS = "ON_UPDATE_ORDER_STATION_SUCCESS"
export const ON_UPDATE_ORDER_STATION_FAIL = "ON_UPDATE_ORDER_STATION_FAIL"
export const CLEAR_ORDER_STATION = "CLEAR_ORDER_STATION"

//CREATE NEW ORDER
export const ON_CREATE_ORDER_SUCCESS = "ON_CREATE_ORDER_SUCCESS"
export const ON_CREATE_ORDER_FAIL = "ON_CREATE_ORDER_FAIL"
export const ON_CREATE_ORDER_FROM_FILE_LOADING =
  "ON_CREATE_ORDER_FROM_FILE_LOADING"
export const ON_CREATE_ORDER_FROM_FILE_SUCCESS =
  "ON_CREATE_ORDER_FROM_FILE_SUCCESS"
export const ON_CREATE_ORDER_FROM_FILE_FAIL = "ON_CREATE_ORDER_FROM_FILE_FAIL"
export const CLEAR_CREATE_ORDER_FROM_FILE = "CLEAR_CREATE_ORDER_FROM_FILE"

//UPDATE ORDER
export const SET_ORDER_TO_UPDATE = "SET_ORDER_TO_UPDATE"
export const ON_UPDATE_ORDER_SUCCESS = "ON_UPDATE_ORDER_SUCCESS"
export const ON_UPDATE_ORDER_FAIL = "ON_UPDATE_ORDER_FAIL"
export const CLEAR_ORDER_UPDATE = "CLEAR_ORDER_UPDATE"

//ORDER STATUSES
export const ON_GET_ORDER_STATUSES_SUCCESS = "ON_GET_ORDER_STATUSES_SUCCESS"
export const ON_GET_ORDER_STATUSES_FAIL = "ON_GET_ORDER_STATUSES_FAIL"
export const ON_UPDATE_ORDER_STATUS_SUCCESS = "ON_UPDATE_ORDER_STATUS_SUCCESS"
export const ON_UPDATE_ORDER_STATUS_FAIL = "ON_UPDATE_ORDER_STATUS_FAIL"
export const CLEAR_ORDER_STATUS_UPDATE = "CLEAR_ORDER_STATUS_UPDATE"

//ON CLOSE MESSAGE
export const ON_CLOSE_MESSAGE = "ON_CLOSE_MESSAGE"

//STATION ACTIONS
export const ON_UPDATE_MESSAGE_SUCCESS = "ON_UPDATE_MESSAGE_SUCCESS"
export const ON_UPDATE_MESSAGE_FAIL = "ON_UPDATE_MESSAGE_FAIL"
export const GET_STATIONS_ALL = "GET_STATIONS_ALL"
export const ON_GET_STATIONS_SUCCESS = "ON_GET_STATIONS_SUCCESS"
export const ON_STATIONS_SET_PAGE = "ON_STATIONS_SET_PAGE"
export const ON_SELECT_STATION_ID = "ON_SELECT_STATION_ID"
export const ON_STATION_SHOW_LOADING = "ON_STATION_SHOW_LOADING"
export const CLEAR_LOCAL_STATIONS_FILTER_SEARCH =
  "CLEAR_LOCAL_STATIONS_FILTER_SEARCH"
export const FETCH_ALL_STATIONS_DATA = "FETCH_ALL_STATIONS_DATA"
export const ON_STATIONS_FILTER_BY_STATION = "ON_STATIONS_FILTER_BY_STATION"
export const ON_STATIONS_FILTER_BY_TEXT = "ON_STATIONS_FILTER_BY_TEXT"
export const ON_SET_DECATHLON_SELECTED_STATION =
  "ON_SET_DECATHLON_SELECTED_STATION"
export const ON_STATIONS_FILTER_BY_ALIVE = "ON_STATIONS_FILTER_BY_ALIVE"

//LOCKER CELLS
export const FETCH_ALL_LOCKERS_STATUSES_SUCCESS =
  "FETCH_ALL_LOCKERS_STATUSES_SUCCESS"
export const FETCH_ALL_LOCKERS_LAYOUT_SUCCESS =
  "FETCH_ALL_LOCKERS_LAYOUT_SUCCESS"
export const CLEAR_LOCKERS_STATUSES = "CLEAR_LOCKERS_STATUSES"

//ORDER TYPE
export const ON_GET_ORDER_TYPES_SUCCESS = "  ON_GET_ORDER_TYPES_SUCCESS"
export const ON_GET_ORDER_TYPES_FAILURE = "    ON_GET_ORDER_TYPES_FAILURE"
export const ON_GET_ORDER_DETAILS_REQUEST = "ON_GET_ORDER_DETAILS_REQUEST"
export const ON_GET_ORDER_DETAILS_SUCCESS = "ON_GET_ORDER_DETAILS_SUCCESS"
export const ON_GET_EXTERNAL_NUMBER_BY_PACKAGE_SUCCESS =
  "ON_GET_EXTERNAL_NUMBER_BY_PACKAGE_SUCCESS"
export const ON_GET_EXTERNAL_NUMBER_BY_PACKAGE_FAIL =
  "ON_GET_EXTERNAL_NUMBER_BY_PACKAGE_FAIL"
export const CLEAR_GET_EXTERNAL_NUMBER = "CLEAR_GET_EXTERNAL_NUMBER"

//ISRAEL POST
export const ON_GET_DELIVERY_ISRAEL_POST_SUCCESS =
  "ON_GET_DELIVERY_ISRAEL_POST_SUCCESS"
export const ON_GET_DELIVERY_ISRAEL_POST_FAIL =
  "ON_GET_DELIVERY_ISRAEL_POST_FAIL"
export const ON_CLEAR_DELIVERY_ISRAEL_POST = "ON_CLEAR_DELIVERY_ISRAEL_POST"

//Failed SMS
export const ON_GET_FAILED_SMS_LOADING = "ON_GET_FAILED_SMS_LOADING"
export const ON_GET_FAILED_SMS_SUCCESS = "ON_GET_FAILED_SMS_SUCCESS"
export const ON_GET_FAILED_SMS_FAIL = "ON_GET_FAILED_SMS_FAIL"
export const ON_CLEAR_FAILED_SMS = "ON_CLEAR_FAILED_SMS"

//BOUser
export const ON_REMOVE_BOUSER_REQUEST = "ON_REMOVE_BOUSER_REQUEST"
export const ON_REMOVE_BOUSER_SUCCESS = "ON_REMOVE_BOUSER_SUCCESS"
export const ON_REMOVE_BOUSER_ERROR = "ON_REMOVE_BOUSER_ERROR"
export const ON_ADD_BOUSER_REQUEST = "ON_ADD_BOUSER_REQUEST"
export const ON_ADD_BOUSER_SUCCESS = "ON_ADD_BOUSER_SUCCESS"
export const ON_ADD_BOUSER_ERROR = "ON_ADD_BOUSER_ERROR"

//ALIVE
export const FETCH_DOWN_STATIONS = "FETCH_DOWN_STATIONS"

import React, { Component } from "react"
import { connect } from "react-redux"
import {
  fetchStationsByRole,
  showStationLoading,
  setDecathlonSelectedStation,
} from "../../../actions"
import "./style.scss"

class DecathlonStationSelectScreen extends Component {
  constructor(props) {
    super(props)
    this.INIT_STATE = {}

    this.state = this.INIT_STATE
  }

  componentDidMount() {
    if (!this.props.authenticated) {
      this.props.history.push("/")
    }
    this.props.fetchStationsByRole()
    this.props.showStationLoading()
  }

  componentWillReceiveProps(newProps) {
    const { authenticated, history, selectedDecathlonStation } = newProps
    if (!authenticated) {
      history.push("/")
    }
    if (selectedDecathlonStation) {
    }
  }

  onSelectStation(stationNumber) {
    this.props.setDecathlonSelectedStation(stationNumber)
    this.props.history.push("/OrdersListScreen")
  }

  createStationElement() {
    const { stationsFilteredListArr } = this.props
    return stationsFilteredListArr.map((station) => {
      return (
        <div
          className="station"
          onClick={() => this.onSelectStation(station.stationNumber)}
        >
          {station.branchName}
        </div>
      )
    })
  }

  render() {
    const {} = this.state
    const { stationsFilteredListArr } = this.props
    return (
      <div className="decathlon-station-select">
        {this.createStationElement()}
      </div>
    )
  }
}

const mapStateToProps = ({ station, users }) => {
  const { stationsLocalSearch, selectedDecathlonStation } = station
  const { stationsFilteredListArr, page } = stationsLocalSearch
  const { authenticated } = users

  return {
    authenticated,
    stationsFilteredListArr,
    selectedDecathlonStation,
  }
}

export default connect(mapStateToProps, {
  fetchStationsByRole,
  showStationLoading,
  setDecathlonSelectedStation,
})(DecathlonStationSelectScreen)
